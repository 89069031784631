.main_wrapper {
    height: 100vh;
    width: 100vw;
}

.main {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--bg-lightest);
}

.header {
    align-items: center;
    background-color: var(--body-background);
    border-bottom: 1px solid var(--spectrum-alias-dropshadow-color);
    box-sizing: border-box;
    display: flex;
    flex: 0 0 var(--global-nav-height);
    padding: 0 8px;

    .icon {
        width: 18px;
        height: 18px;
    }
}



.nav_tool {
    position: relative;
    height: 32px;
    min-width: 55px;
    padding: 0 6px;
    border-width: 1px;
    border-radius: 4px;
    font-size: 14px;
    /*font-weight: bold;*/
    background-color: transparent;
    border-color: transparent;
    color: #4b4b4b;
    display: flex;
    align-items: center;
}

.spacer {
    width: 100%;
}

.vertical_line {
    height: 16px;
    width: 1px;
    background: var(--spectrum-global-color-gray-300);
}

.flex-vertical-certer {
    display: flex;
    align-items: center
}

.nav_icon_tool {
    width: 18px;
    height: 18px;
}

.cursor-normal {
    cursor: default;
}

.fluent-nav-text:empty {
    display: none;
}