.dropdown {
  display: flex;
  flex-direction: column;
  &--open {
    .dropdown__menu {
      display: block;
    }
  }
  &--disabled {
    .dropdown__menu {
    }
  }
  &--selectList {
    width: 100%;

    .dropdown {
      &-caret {
        margin-left: auto;
      }
      &__menu {
        max-height: 320px;
        overflow: auto;
      }
    }
  }
  &__button {
  }
  &__wrapper {
    position: relative;
  }
  &__menu {
    list-style: none;
    margin: 0;
    padding: spacing(0.5) 0;
    position: absolute;
    top: spacing(0.5);
    background-color: #fff;
    border: $layoutBorder;
    min-width: spacing(25);
    display: none;
    z-index: 10;
    border-radius: $formBorderRadius;
  }
  &__separator {
    height: 1px;
    border-bottom: $layoutBorder;
    margin: spacing(0.5) 0;
  }
  &__menuItem {
    button,
    a {
      cursor: pointer;
      color: inherit;
      text-decoration: none;
      white-space: nowrap;
    }
    > * {
      padding: spacing(0.75) spacing(2);
      display: flex;
      align-items: center;
      min-height: spacing(5);
    }
    .icon {
      margin-right: spacing(1.5);
    }
    &:hover {
      color: $colorSecondary;
    }
  }

  &--alignLeft {
    .dropdown__menu {
      left: 0;
      right: auto;
    }
  }
  &--alignCenter {
    .dropdown__menu {
      text-align: center;
      right: 50%;
      transform: translateX(50%);
    }
  }
  &--alignRight {
    .dropdown__menu {
      right: 0;
    }
  }
}
