:root {
  --spectrum-alias-dropshadow-color: rgba(0, 0, 0, 0.15);
  --spectrum-global-color-gray-50: #fff;
  --backgroundCanvas: #fff;
  --fill-color: var(--backgroundCanvas);
  --global-nav-height: 64px;
  --spectrum-global-color-gray-300: #e1e1e1;
  --accent-fill-rest: var(--backgroundCanvas) !important;
  --spectrum-global-color-gray-75: #fafafa;
  --primary: rgb(199, 18, 23);
  --secondary: #3660b3;
  --secondary_light: #86a0d1;
  --text: #FFF;
  --primary-background: rgba(0, 0, 0, 0);
  --accent-fill-rest: var(--secondary);
  --spectrum-global-color-gray-700: #020101;
  --spectrum-global-color-gray-900: #2c2c2c;
  --icon-size: 36px;
  --gray-100: #f5f5f5;
  --successcol_light: #cfffd7;
  --successcol_dark: #339944;
  --successcol_lightbor: #3ebd53;
}


$text: #252525;
$textMuted: #999999;
$textContrast: #fff;
$bodyBackground: #fff;
$bodyBackgroundHover: #f5f5f5;
$bodyDarkBackground: #0000;
$bodyDarkBackgroundHover: #383838;

$primaryLight: #dd7174;
$primary: #c71217;
$primaryDark: #770b0e;
$primaryContrast: #fff;

$secondary: #3660b3;
$secondaryLight: #86a0d1;
$secondaryContrast: #fff;

$bgLight: #eeeff2;
$bgLighter: #f9f8f9;
$bgLightest: #fafafa;
$bgDark: #3e3e3e;
$bgDarker: #2e2e2e;
$bgDarkest: #1e1e1e;

$grayLighter: #f9f8f9;
$grayLighterContrast: #252525;
$grayLight: #eeeff2;
$grayLightContrast: #252525;
$gray: #b1b1b1;
$grayContrast: #fff;
$grayDark: #203a6b;
$grayDarkContrast: #fff;
$grayDarker: #162648;
$grayDarkerContrast: #fff;

$success: #007E33;
$successContrast: #fff;
$successLight: #00C851;
$successLighter: #00C851;
$info: #0099CC;
$infoLight: #99BFCC;
$infoLighter: #BAEDFF;
$infoContrast: #fff;
$warning: #FF8800;
$warningContrast: #fff;
$warningLight: #FFC27C;
$warningLightContrast: #fff;
$warningLighter: #FFF3E5;
$warningLighterContrast: #000;
$errorDark: #880000;
$error: #cc0000;
$errorContrast: #fff;
$errorLight: #ff4444;
$errorLighter: #ffeeee;


// Light Theme Grays
$gray-lightest: #f7f7f7;
$gray-lighter: #e0e0e0;
$gray-light: #cccccc;

// Light Theme Contrasts
$gray-lightest-contrast: #333333;
$gray-lighter-contrast: #444444;
$gray-light-contrast: #555555;

// Dark Theme Grays
$gray-lightest-dark: #333333;
$gray-lighter-dark: #444444;
$gray-light-dark: #555555;

// Dark Theme Contrasts
$gray-lightest-dark-contrast: #f7f7f7;
$gray-lighter-dark-contrast: #e0e0e0;
$gray-light-dark-contrast: #cccccc;