.linkList {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;

  @include media-down(mobile) {
    align-items: center;
  }

  &__title {
    font-weight: $fontWeightBold;
    margin-bottom: spacing(2);

    @include media-down(mobile) {
      text-align: center;
    }
  }
  &__item {
    display: flex;
    line-height: 1.3;
    a {
      color: inherit;
      padding: spacing(1) 0;
      text-decoration: none;
      &:hover,
      &:active {
        color: $linkHoverColor;
      }
    }
  }
}
