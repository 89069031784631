@mixin media-up($device) {
  @if map-has-key($devices, $device) {
    $device-value: map-get($devices, $device);
    @media (min-width: $device-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$device}.';
  }
}
@mixin media-down($device) {
  @if map-has-key($devices, $device) {
    $device-value: map-get($devices, $device);
    @media (max-width: ($device-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$device}.';
  }
}
@mixin media-between($lower, $upper) {
  @if map-has-key($devices, $lower) and map-has-key($devices, $upper) {
    $lower-breakpoint: map-get($devices, $lower);
    $upper-breakpoint: map-get($devices, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($devices, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($devices, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
