$errorcol_light: #ffdddf;
$errorcol_lightbor: #ff9da3;
$errorcol_dark: #9e0000;
$successcol_light: #cfffd7;
$successcol_dark: #339944;
$successcol_lightbor: #3ebd53;
$labelcolor: rgba(0, 0, 0, 0.4);
$errodark: #e8685b;

// .iptbx {
//   position: relative;
// }

@mixin messageBox {
  display: flex;
  align-items: flex-start;
  position: fixed;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -17%);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  border-radius: 5px;
  min-height: 45px;
  z-index: 9999;
  flex-wrap: wrap;
  align-content: center;
}

.redborder {

  input,
  textarea,
  select {
    border: 1px solid $errorcol_dark !important;
  }

  label {
    color: $errorcol_dark !important;
  }
}

.error_msg {
  width: 100%;
  color: $errorcol_dark;
  display: inline-block;
  font-size: 12px;
}

.error_box {
  border-left: 2px solid $errodark;
  border-right: 1px solid $errorcol_lightbor;
  border-top: 1px solid $errorcol_lightbor;
  border-bottom: 1px solid $errorcol_lightbor;
  background-color: $errorcol_light;
  color: $errorcol_dark;
  @include messageBox();

  // svg {
  //   margin-right: 10px;
  // }

  .ic1 {
    margin-top: 3px;
  }

  .msg__icon {
    position: absolute;
    left: 5px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    div {
      width: 18px;
      height: 18px;
    }
  }

  .clsbtn__cross {
    position: absolute;
    right: 5px;
    top: 0;
    height: 100%;
    cursor: pointer;

    // margin-left: 25px;

    // margin-top: -2px;
    // display: inline-flex;
    div {
      width: 18px;
      height: 18px;
    }

  }

  button {
    border: none;
    background-color: transparent;
    color: $errodark;
    padding: 0;
  }

  .pswrdblock {
    p {
      margin: 0;
    }
  }
}

.success_box {
  background-color: $successcol_light;
  border-left: 2px solid $successcol_dark;
  border-right: 1px solid $successcol_lightbor;
  border-top: 1px solid $successcol_lightbor;
  border-bottom: 1px solid $successcol_lightbor;
  box-shadow: 0px 3px 7px $labelcolor;
  color: $successcol_dark;
  @include messageBox();

  // svg {
  //   margin-right: 10px;
  // }

  .ic1 {
    margin-top: 3px;
  }

  .msg__icon {
    position: absolute;
    left: 5px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    div {
      width: 18px;
      height: 18px;
    }
  }

  .clsbtn__cross {
    position: absolute;
    right: 5px;
    top: 0;
    height: 100%;
    cursor: pointer;

    // margin-left: 25px;

    // margin-top: -2px;
    // display: inline-flex;
    div {
      width: 18px;
      height: 18px;
    }

  }

  button {
    border: none;
    background-color: transparent;
    color: #339944;
    padding: 0;
  }

  .pswrdblock {
    p {
      margin: 0;
    }
  }
}