.hint {
  margin: spacing(6) 0;
  padding: spacing(1) 0;
  background-color: #ffffff;

  .icon {
    width: spacing(5);
    height: spacing(4);
  }

  &--info {
    padding-left: spacing(2);
    border-color: $bg-light;
    border-left: solid spacing(1) $colorInfo;
  }

  &--warning {
    padding-left: spacing(2);
    border-color: $bg-light;
    border-left: solid spacing(1) $colorWarning;
  }

  &--error {
    padding-left: spacing(2);
    border-color: $bg-light;
    border-left: solid spacing(1) $colorError;
  }

  &--lighter {
    background-color: $bg-lighter;
  }

  &--tip {
    padding: spacing(3) spacing(2);
    background-color: $bg-lighter;
    border-radius: $layoutBorderRadius;

    @include media-up(mobile) {
      display: grid;
      grid-template-columns: spacing(6) 1fr;
      grid-gap: spacing(2);
    }
  }
  &--pro {
    padding: spacing(3) spacing(2);
    border-radius: $layoutBorderRadius;
    border: $layoutBorder;
    border-color: $bg-light;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.hintIcon {
  &--proOnly {
    display: inline-block;
    padding: spacing(0.5) spacing(1);
    background-color: #2e3137;
    color: #fff;
    font-size: $fontSizeXS;
    border-radius: 4px;
    margin-bottom: spacing(2);
  }
}
