.logo {
  display: flex;
  align-items: center;
  &__svg {
    height: 40px;
    @include media-down(mobile) {
      height: 32px;
    }
  }
  &__ft {
    fill: #2e3137;
  }
  &__fp {
    fill: $primary-500;
  }
}
