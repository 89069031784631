h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  line-height: 1.2;
  margin: 1em 0;
  color: $titleTextColor;
  a {
    text-decoration: none;
    color: inherit;
    &:hover,
    &:active {
      color: $linkHoverColor;
    }
  }
}

h1,
.h1 {
  font-size: $fontSizeH1;
  font-weight: $fontWeightBold;
  @include media-down(tablet) {
    font-size: $fontSizeH1tablet;
  }
  @include media-down(mobile) {
    font-size: $fontSizeH1mobile;
  }
}
h2,
.h2 {
  font-size: $fontSizeH2;
  font-weight: $fontWeightBold;
  @include media-down(tablet) {
    font-size: $fontSizeH2tablet;
  }
  @include media-down(mobile) {
    font-size: $fontSizeH2mobile;
  }
}

// .h2 {
//   color: $colorSecondary;
//   &:after {
//     content: '';
//     display: block;
//     width: 60px;
//     height: 4px;
//     background-color: $colorPrimary;
//     margin-top: spacing(1);
//   }
// }

h3,
.h3 {
  font-size: $fontSizeH3;
  font-weight: $fontWeightBold;
  @include media-down(tablet) {
    font-size: $fontSizeH3tablet;
  }
  @include media-down(mobile) {
    font-size: $fontSizeH3mobile;
  }
}
h4,
.h4 {
  font-size: $fontSizeH4;
  font-weight: $fontWeightSemiBold;
  @include media-down(tablet) {
    font-size: $fontSizeH4tablet;
  }
  @include media-down(mobile) {
    font-size: $fontSizeH4mobile;
  }
}
h5,
.h5 {
  font-weight: $fontWeightSemiBold;
  font-size: $fontSizeH5;
}

.display1 {
  font-size: $fontSizeH1;
}

.display2 {
  font-size: $fontSizeH1tablet;
}

p {
  margin: 0 0 1em 0;
}

a {
  color: inherit;
  color: $linkColor;
  &:hover,
  &:active {
    color: $linkHoverColor;
  }
}

ul {
  padding-left: spacing(3);
}

dt {
  font-weight: 600;
}
dd {
  margin-left: spacing(2);
  margin-bottom: spacing(2);
}
