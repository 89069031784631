@use 'color';


:root {

    --bg-lightest: #{$bgLightest};
    --bg-light: #{$bgLight};
    --body-background: #{$bodyBackground};
    --body-background-hover: #{$bodyBackgroundHover};
    --text: #{$text};
    --text-muted:#{$textMuted};

    --color-gray-lightest: #{$gray-lightest};
    --color-gray-lighter: #{$gray-lighter};
    --color-gray-light: #{$gray-light};


    --color-gray-lightest-contrast: #{$gray-lightest-contrast};
    --color-gray-lighter-contrast: #{$gray-lighter-contrast};
    --color-gray-light-contrast: #{$gray-light-contrast};

    --color-icon-text: #{$bgLightest};
}




:root[data-theme='dark'] {
    --bg-lightest: #{$bgDarkest};
    --bg-light: #{$bgDark};
    --body-background: #{$bgDarkest};
    --body-background-hover: #{$bodyDarkBackgroundHover};
    --text:#{$textContrast};
    --text-muted: #{lighten($textMuted, 20%)};

    --color-gray-lightest: #{$gray-lightest-dark};
    --color-gray-lighter: #{$gray-lighter-dark};
    --color-gray-light: #{$gray-light-dark};

    --color-gray-lightest-contrast: #{$gray-lightest-dark-contrast};
    --color-gray-lighter-contrast: #{$gray-lighter-dark-contrast};
    --color-gray-light-contrast: #{$gray-light-dark-contrast};

    --color-icon-text: #{$bgLightest};
}