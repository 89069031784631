.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: spacing(3);
  height: spacing(2.5);
  &--sm {
    width: spacing(2);
    height: spacing(2);
  }
  &--lg {
    width: spacing(3.5);
    height: spacing(3);
  }
  &--xl {
    width: spacing(5);
    height: spacing(4);
  }
  svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }
}
