@import './variable/breakpoints';
@import './variable/devices';

.table_wrapper {
    display: flex;
    flex-direction: column !important;
    flex: 1 0 auto;
    flex-direction: row;
    // margin-top: 20px;
    // min-height: 100%;
    // padding: 0 50px;
    padding: 0 50px;
    position: relative;

    @include media-down(tablet) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.table__header__caption {
    div {
        color: var(--text)
    }
}

.table__row {
    color: var(--text) !important;

    &:hover {
        background: var(--body-background-hover) !important;
    }

}