.formRow {
  margin: spacing(2) 0;

  &--required {
    .formRow {
      &__label {
        &:after {
          content: '*';
          line-height: 0.8;
          margin-left: 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }

  &--focus {
    .formRow {
      box-shadow: $elevate-1;
    }
  }

  &--error {
    .formRow {
      &__label {
        color: $colorError;
      }

      &__control,
      &__input {
        color: $colorError;
        border-color: $colorError;
      }
    }
  }

  &__label {
    font-size: $fontSizeSM;
    color: var(--text)
  }

  &__control {
    display: flex;
    align-items: center;
    min-height: 44px;
  }

  &__input {
    display: flex;
    align-items: center;
    border: $layoutBorder;
    border-radius: $formBorderRadius;
    min-height: spacing(6);
    padding: 0 4px;
    background-color: #fff;
    color: #000;
    flex-grow: 1;

    input,
    select,
    textarea {
      padding: spacing(0.4) spacing(1);
      flex-basis: 100%;
      border: none;
      color: inherit;
      background-color: transparent;
    }

    textarea {
      padding: spacing(1) spacing(1);
      height: spacing(12);
    }

    &AddOn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 42px;
      border-top-right-radius: $formBorderRadius;
      border-bottom-right-radius: $formBorderRadius;
      background-color: $bg-light;
      border: none;
      margin-right: -4px;
      cursor: pointer;
    }

    &Icon {
      width: 32px;
      height: 24px;
      margin: 0 8px;
    }
  }

  &__errorMessage {
    margin: spacing(1) 0 0 0;
    color: $colorError;
    font-size: $fontSizeSM;
    display: block;
  }

  &__hint {
    margin: spacing(1) 0 0 0;
    font-size: $fontSizeSM;
    display: block;
    color: var(--text)
  }
}