.btnGroup {
  position: relative;
  display: flex;
  align-items: center;
  border-collapse: collapse;
  background-color: $bg-light;
  border-radius: $formBorderRadius;
  min-height: spacing(6);
  max-width: 100%;
  overflow: hidden;
  input {
    display: none;
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding: spacing(1) spacing(0.5);
    height: 100%;
    width: 160px;
    flex-grow: 1;
    color: $textColorMuted;
    &:not(:first-child) {
      border-left: solid 1px #fff;
    }
    &--selected {
      color: $colorSecondaryContrast;
      transition: all 0.2s ease-out;
      cursor: default;
    }
  }
  &.type-radio {
    .btnGroup__btn {
      border-left: none !important;
    }
  }
  &__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    min-width: 1rem;
    background-color: $colorSecondary;
    transition: all 0.2s ease-out;
  }
  &__label {
    font-size: $fontSizeXS;
    text-align: center;
  }
  .icon {
    margin: spacing(0.5) 0 spacing(1) 0;
    width: spacing(4);
    height: spacing(3.5);
  }
}
