.accordion {
  &__card {
    background-color: $bodyBg;
    border-radius: $layoutBorderRadius;
    margin-bottom: spacing(3);
    color: $textColor;
    box-shadow: $elevate-2;
    padding: spacing(1) spacing(3);
    &--open {
      h3 {
        &::after {
          transform: rotate(90deg);
          transition: all ease-in 0.2s;
        }
      }
    }
  }
  &__header {
    position: relative;
    h3 {
      cursor: pointer;
      display: flex;
      padding: 4px spacing(4) 4px 0;
      font-size: $fontSizeH4;
      color: $secondary-500;
      &:hover {
        color: $linkHoverColor;
      }
      &::after {
        content: '❯';
        position: absolute;
        right: -8px;
        top: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: spacing(4);
        height: spacing(4);
        border-radius: 50%;
        background-color: $secondary-100;
        color: $secondary-500;
        transition: all ease-in 0.2s;
        margin-right: spacing(1);
      }
    }
  }
  &__body {
    overflow: hidden;
    height: 0;
    transition: all ease-in 0.2s;
    > div {
      padding: spacing(2) 0;
    }
    .lnkReadMore {
      text-decoration: none;
      color: $secondary-500;
      font-weight: $fontWeightSemiBold;
    }
  }
}
