@import './variable/base';
.ct-404 {
    top: 0;
    min-height: 100%;
    position: relative;
    background-color: #101970;
    margin: 0;
    padding: 0;
    /* bottom: auto; */
    overflow: hidden;
  .row {
    color: #fff;
    position: relative;
    z-index: 2;
    text-align: center;
    /* margin-top: 400px; */
    top: 0;

    @include media-down(tablet) {
      margin-top: 300px;
    }

    @include media-down(mobile) {
      margin-top: 200px;
    }
    h1 {
      color: rgba(255, 255, 255, 0.9);
      margin: 24px 0;
    }
    h2 {
      color: rgba(255, 255, 255, 0.9);
      font-weight: $fontWeightSemiBold;
      margin: 48px 0;
    }
  }
}

.bg404 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
