.formRow {
  margin: spacing(3) 0;
  &--required {
    .formRow {
      &__label {
        &:after {
          content: '*';
          line-height: 0.8;
          margin-left: 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }
  &--focus {
    .formRow {
      box-shadow: $elevate-1;
    }
  }
  &--error {
    .formRow {
      &__label {
        color: $colorError;
      }
      &__control,
      &__input {
        color: $colorError;
        border-color: $colorError;
      }
    }
  }
  &__label {
    font-size: $fontSizeSM;
  }
  &__control {
    display: flex;
    align-items: center;
  }
  &__input {
    display: flex;
    align-items: center;
    border: $layoutBorder;
    border-radius: $formBorderRadius;
    min-height: spacing(5.5);
    padding: 0 4px;
    background-color: #ffffff;
    color: #000000;
    flex-grow: 1;
    input,
    select,
    textarea {
      padding: spacing(0.4) spacing(1);
      flex-basis: 100%;
      border: none;
      color: inherit;
      background-color: transparent;
    }
    textarea {
      padding: spacing(1) spacing(1);
      height: spacing(12);
    }
    .icon {
      width: 18px;
      height: 18px;
      margin: 0 6px;
    }
  }
  &__errorMessage {
    margin: spacing(1) 0 0 0;
    color: $colorError;
    font-size: $fontSizeSM;
    display: block;
  }
  &__hint {
    margin: spacing(1) 0 0 0;
    font-size: $fontSizeSM;
    display: block;
  }
}
