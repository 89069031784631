/* font size */
$fontSizeBase: 15px;
$fontSizeLG: 17px;
$fontSizeSM: 14px;
$fontSizeXS: 12px;

$fontSizeH1: 56px;
$fontSizeH1tablet: 40px;
$fontSizeH1mobile: 32px;

$fontSizeH2: 28px;
$fontSizeH2tablet: 26px;
$fontSizeH2mobile: 22px;

$fontSizeH3: 22px;
$fontSizeH3tablet: 20px;
$fontSizeH3mobile: 18px;

$fontSizeH4: 18px;
$fontSizeH4tablet: 16px;
$fontSizeH4mobile: 16px;

$fontSizeH5: $fontSizeBase;

$fontWeightDefault: 400;
$fontWeightSemiBold: 500;
$fontWeightBold: 600;




// Font Sizes
$font-size-normal: 15px;
$font-size-sm: 14px;
$font-size-xs: 12px;
$font-size-lg: 17px;
$font-size-xl: 18px;
$font-size-h1: 40px;
$font-size-h2: 26px;
$font-size-h3: 22px;
$font-size-h4: 18px;
$font-size-h5: 15px;
$font-size-display1: 56px;
$font-size-display2: 28px;
$font-size-display3: 22px;

// Line Heights
$line-height-normal: 1.375rem;
$line-height-sm: 1.25rem;
$line-height-xs: 1.125rem;
$line-height-lg: 1.5rem;
$line-height-xl: 1.625rem;
$line-height-h1: 3rem;
$line-height-h2: 2rem;
$line-height-h3: 1.75rem;
$line-height-h4: 1.5rem;
$line-height-h5: 1.375rem;
$line-height-display1: 4rem;
$line-height-display2: 2.125rem;
$line-height-display3: 1.75rem;