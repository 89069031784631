a.card {
  text-decoration: none;
}
.card {
  display: flex;
  flex-direction: column;
  background-color: $bodyBg;
  border-radius: $layoutBorderRadius;
  margin-bottom: spacing(4);
  color: $textColor;
  box-shadow: $elevate-2;

  &:hover {
    color: $textColor;
  }
  & > * {
    padding: spacing(1) spacing(3);
    &:first-child {
      padding-top: spacing(3);
    }
    &:last-child {
      padding-bottom: spacing(3);
    }
  }
  &--light {
    background-color: $bg-light;
  }
  &--lighter {
    background-color: $bg-lighter;
  }
  &--lightest {
    background-color: $bg-lightest;
  }
  &--dark {
    background-color: $colorSecondary;
    color: $colorSecondaryContrast;
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
      color: $colorSecondaryContrast;
    }
  }
  &--flat {
    box-shadow: none;
    border: solid 1px $bg-light;
  }
  &--noBorder {
    box-shadow: none;
    border: none;
  }
  &__header {
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
      margin: 0;
      padding: 0;
    }
    padding-bottom: spacing(2);
    border-bottom: $layoutBorder;
    &--flex {
      display: flex;
      align-items: center;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
      margin: 0.5rem 0 1rem 0;
    }
    .btnRow {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__image {
    display: flex;
    &--alignCenter {
      justify-content: center;
    }
    &--alignRight {
      justify-content: flex-end;
    }
    img {
      width: auto;
      max-width: 100%;
      display: block;
    }
  }
  &__media {
    display: block;
    background-color: $bg-light;
    overflow: hidden;
    &:first-child {
      border-top-left-radius: $layoutBorderRadius;
      border-top-right-radius: $layoutBorderRadius;
    }
    &:last-child {
      border-bottom-left-radius: $layoutBorderRadius;
      border-bottom-right-radius: $layoutBorderRadius;
    }
    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__footer {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-grow: 0;
    }
    li {
      margin: 0;
      padding: 0;
    }
  }
}

.cardList {
  display: grid;
  grid-gap: spacing(2);
  margin: spacing(4) 0;
  .card {
    margin: 0;
  }
}
