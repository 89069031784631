// https://noeldelgado.github.io/shadowlord/#c71217
$primary-50: #f9e7e8;
$primary-100: #f4d0d1;
$primary-200: #e9a0a2;
$primary-300: #dd7174;
$primary-400: #d24145;
$primary-500: #c71217;
$primary-600: #9f0e12;
$primary-700: #770b0e;
$primary-800: #500709;
$primary-900: #280405;

// https://noeldelgado.github.io/shadowlord/#3660b3
$secondary-25: #f5f7fb;
$secondary-50: #ebeff7;
$secondary-100: #ebeff7;
$secondary-150: #c3cfe8;
$secondary-200: #afbfe1;
$secondary-300: #86a0d1;
$secondary-400: #5e80c2;
$secondary-450: #4a70bb;
$secondary-500: #3660b3;
$secondary-600: #2b4d8f;
$secondary-700: #203a6b;
$secondary-800: #162648;
$secondary-900: #0b1324;

$bodyBg: #ffffff;
$titleTextColor: #0b1324;
$textColor: #333333;
$textColorMuted: rgba(51, 51, 51, 0.6);
$textColorContrast: #ffffff;
$textColorContrastMuted: rgba(255, 255, 255, 0.6);

$bg-light: #eeeff2;
$bg-lighter: #f9f8f9;

$linkColor: $secondary-600;
$linkHoverColor: $secondary-300;

$colorPrimaryLight: $primary-300;
$colorPrimary: $primary-500;
$colorPrimaryDark: $primary-700;
$colorPrimaryContrast: #ffffff;

$colorSecondaryLight: $secondary-300;
$colorSecondary: $secondary-500;
$colorSecondaryDark: $secondary-700;
$colorSecondaryContrast: #ffffff;

$colorGreyLighter: #f4f7fa;
$colorGreyLight: #e0e4e8; //#d8dadc;
$colorGrey: #b6b8ba;
$colorGreyDark: #626466;
$colorGreyDarker: #424446;

/* signal colors */
$colorInfo: #0099cc;
$colorInfoContrast: #ffffff;
$colorSuccess: #0eb852;
$colorSuccessContrast: #ffffff;
$colorWarning: #ff8800;
$colorWarningContrast: #ffffff;
$colorError: #cc0000;
$colorErrorContrast: #ffffff;

// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=35B0X0kw0w0jzFtoUzhx9o5CEi4 */

// Feel free to copy&paste color codes to your application */

// As hex codes */

// $color-primary-0: #FB000D;	// Main Primary color */
// $color-primary-1: #FC626A;
// $color-primary-2: #FB3842;
// $color-primary-3: #BD0009;
// $color-primary-4: #8D0007;

// $color-secondary-1-0: #1144AA;	// Main Secondary color (1) */
// $color-secondary-1-1: #597EC6;
// $color-secondary-1-2: #3660B3;
// $color-secondary-1-3: #0C3380;
// $color-secondary-1-4: #072560;

// $color-secondary-2-0: #FFFC00;	// Main Secondary color (2) */
// $color-secondary-2-1: #FFFD63;
// $color-secondary-2-2: #FFFC39;
// $color-secondary-2-3: #C0BD00;
// $color-secondary-2-4: #908E00;

// // As RGBa codes */

// $rgba-primary-0: rgba(251,  0, 13,1);	// Main Primary color */
// $rgba-primary-1: rgba(252, 98,106,1);
// $rgba-primary-2: rgba(251, 56, 66,1);
// $rgba-primary-3: rgba(189,  0,  9,1);
// $rgba-primary-4: rgba(141,  0,  7,1);

// $rgba-secondary-1-0: rgba( 17, 68,170,1);	// Main Secondary color (1) */
// $rgba-secondary-1-1: rgba( 89,126,198,1);
// $rgba-secondary-1-2: rgba( 54, 96,179,1);
// $rgba-secondary-1-3: rgba( 12, 51,128,1);
// $rgba-secondary-1-4: rgba(  7, 37, 96,1);

// $rgba-secondary-2-0: rgba(255,252,  0,1);	// Main Secondary color (2) */
// $rgba-secondary-2-1: rgba(255,253, 99,1);
// $rgba-secondary-2-2: rgba(255,252, 57,1);
// $rgba-secondary-2-3: rgba(192,189,  0,1);
// $rgba-secondary-2-4: rgba(144,142,  0,1);

// Generated by Paletton.com © 2002-2014 */
// http://paletton.com */

// $indigo-100: tint-color($indigo, 80%) !default;
// $indigo-200: tint-color($indigo, 60%) !default;
// $indigo-300: tint-color($indigo, 40%) !default;
// $indigo-400: tint-color($indigo, 20%) !default;
// $indigo-500: $indigo !default;
// $indigo-600: shade-color($indigo, 20%) !default;
// $indigo-700: shade-color($indigo, 40%) !default;
// $indigo-800: shade-color($indigo, 60%) !default;
// $indigo-900: shade-color($indigo, 80%) !default;

// // primary (500): #e31b23;
// $primary-50: #fce8e9;
// $primary-100: #f9d1d3;
// $primary-200: #f4a4a7;
// $primary-300: #ee767b;
// $primary-400: #e9494f;
// $primary-500: #e31b23;
// $primary-600: #b6161c;
// $primary-700: #881015;
// $primary-800: #5b0b0e;
// $primary-900: #2d0507;

// // secondary (500): #3660b3;
// $secondary-50: #ebeff7;
// $secondary-100: #d7dff0;
// $secondary-200: #afbfe1;
// $secondary-300: #86a0d1;
// $secondary-400: #5e80c2;
// $secondary-500: #3660b3;
// $secondary-600: #2b4d8f;
// $secondary-700: #203a6b;
// $secondary-800: #162648;
// $secondary-900: #0b1324;

// $colorGreyBase: #42464d;
// $colorGreyLighter: #dee7f4;
// $colorGreyLight: #d0d9e5;
// $colorGrey: #c6cad1;
// $colorGreyDark: #67696d;
// $colorGreyDarker: #444649;

// $colorGreyLighter: lighten($colorGreyBase, 60%);
// $colorGreyLight: lighten($colorGreyBase, 20%);
// $colorGrey: lighten($colorGreyBase, 20%);
// $colorGreyDark: lighten($colorGreyBase, 20%);
// $colorGreyDarker: lighten($colorGreyBase, 20%);
