.infoBox {
  margin: spacing(4) 0;
  padding: spacing(3);
  background-color: #fff;
  border-radius: $layoutBorderRadius;
  box-shadow: $elevate-1;
  @include media-up(mobile) {
    display: grid;
    grid-gap: spacing(3);
    grid-template-columns: spacing(6) 1fr;
  }

  @include media-down(mobile) {
    text-align: center;
  }

  &__icon {
    color: $textColorMuted;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-down(mobile) {
      margin-top: spacing(2);
      margin-bottom: spacing(4);
    }

    .icon {
      width: spacing(5);
      height: spacing(5);

      @include media-down(mobile) {
        width: spacing(7);
        height: spacing(7);
      }
    }
  }
  &__content {
    h3,
    h4 {
      margin-top: spacing(1);
    }
    > a {
      text-decoration: none;
      color: inherit;
      &:hover {
        color: $linkHoverColor;
      }
    }
  }
}
