* {
  outline: none;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Montserrat-Optional', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: $fontWeightDefault;
  line-height: 1.68;
  letter-spacing: 0.02em;
  color: $textColor;
  background-color: $bodyBg;
  min-height: 101vh;
}

button,
input,
textarea,
select {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  letter-spacing: 0.02em;
}

button {
  background-color: transparent;
  border: none;
}

pre {
  padding: spacing(1);
  background-color: $bg-lighter;
  overflow-x: auto;
}

hr {
  border: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: spacing(5) 0;
}
