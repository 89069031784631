  @mixin icon_common_styles($color) {
      border-radius: 5px;
      padding: 1px;

      @if($color) {
          background: $color;
      }
  }



  .toolIcon {
      position: relative;
      width: 8em;
      height: 8em;
      font-size: 8px;
      // @include media-down(mobile) {
      //   font-size: 6px;
      // }
  }

  /* Tool icon color Start  */
  .pdfToPowerpoint {
      @include icon_common_styles(#C94324);


  }

  .pdfToWord {
      @include icon_common_styles(#00389F);

  }

  .pdfToExcel {
      @include icon_common_styles(#178643);

  }

  .pdfToImage {
      @include icon_common_styles(#00D4C2);

  }

  .pdfToEPUB {
      @include icon_common_styles(#00DF04);

  }

  .pdfOCR {
      @include icon_common_styles(#D05FFF);

  }

  .addBarcode {
      @include icon_common_styles(#3CBF00);

  }

  .addWaterMark {
      @include icon_common_styles(#005F0C);

  }

  .compressPDF {
      @include icon_common_styles(#C71217);

  }

  .jobHistory,
  .myDocs {
      @include icon_common_styles(#F7AB00);

  }

  .sharedDocs {
      @include icon_common_styles(#09AA9E);

  }

  .preparePrint,
  .workflow {
      @include icon_common_styles(#0069E9);

  }

  .repairPDF {
      @include icon_common_styles(#C71217);

  }

  .optimiseWeb {
      @include icon_common_styles(null);
      background-image: linear-gradient(to bottom right, #3967AA, #02B7D1, #01A7B8);
  }

  .protectPDF {
      @include icon_common_styles(#00A411);

  }

  .unlockPDF {
      @include icon_common_styles(#185ABD);

  }

  .extractPDF {
      @include icon_common_styles(#C71217);

  }

  .pdfConverter {
      @include icon_common_styles(#C71217);

  }

  .settingsPDF {
      @include icon_common_styles(#00A411);

  }

  .pdfEditor {
      @include icon_common_styles(#ff0015);


  }

  .signPDF {
      @include icon_common_styles(#ff0015);


  }

  .add_page_number,
  .pdfa,
  .find_and_replace,
  .merge_pdf_overlay {
      @include icon_common_styles(#ff0015);

      &>svg {
          height: 33px;
          width: 36px;

      }


  }

  .mergePDF {
      @include icon_common_styles(#00C914);


  }

  .splitPDF {
      @include icon_common_styles(#ff0015);


  }

  .developer {
      @include icon_common_styles(#2BB4C0);

  }

  .api_keys {
      @include icon_common_styles(#F7AB00);
  }

  .help {
      @include icon_common_styles(null);
  }

  /* Tool icon color End  */