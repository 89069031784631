﻿@import './variable/breakpoints';
@import './variable/devices';
@import './variable/fonts';

.homeTab {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: calc(100vh - var(--global-nav-height));
}

.homeTab>.homeTab_content {
    flex: 1 1 auto;
    min-width: 0;
    overflow-y: auto;
    padding: 0;

}

.homeTab .homeTab_content .homeTab_banneer {
    background: var(--secondary);
    display: flex;
    position: relative;
    display: flex;
    gap: 32px;
    justify-content: center;
    margin: 0 auto 56px auto;
    padding: 32px 50px 0;
    transition: none;
}

.homeTab_banneer_content {
    display: flex;
    background: var(--bg-lightest);
    border: 1px solid var(--spectrum-global-color-gray-300);
    border-radius: 8px;
    flex: 4;
    justify-content: space-between;
    margin-bottom: -32px;
    padding: 32px 40px;
    position: relative;
}

.homeTab_banneer_btn {
    border-width: 2px;
    border-style: solid;
    border-radius: 16px;
    min-height: 32px;
    height: auto;
    min-width: 72px;
    padding: 3px 14px 5px;
    font-size: 14px;
    font-weight: 700;
    background: var(--bg-lightest);
}

.homeTab_actionBlock {
    align-items: center;
    align-self: center;
    background-color: var(--body-background);
    border: 1px solid var(--spectrum-global-color-gray-300);
    border-radius: 0 0 4px 4px;
    box-sizing: border-box;
    display: flex;
    gap: 40px;
    height: 100%;
    outline: 1px solid transparent;
    padding: 32px 40px 32px 32px;
    width: 25vw;

    .banner_thumbnail {
        display: flex;
        justify-content: center;
        position: relative;
        width: 30%;
        align-items: center;

        .logo_pdf4me {
            height: 100%;
            width: 10vw;
        }
    }
}

.toolTab_wrapper {
    margin: 0 auto;
    padding: 0 50px;
}

.banner__title {
    font-size: $font-size-h2;
    line-height: $line-height-h2;
}

.banner__subtitle {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
}

@media only screen and (max-width: 1024px) {

    .homeTab_actionBlock {
        width: 35vw;
    }
}

@media only screen and (max-width: 1280px) {

    .homeTab_actionBlock {
        width: 30vw;
    }
}

@media (max-width: 767px) {
    .homeTab_banneer_content {
        flex-direction: column;
        gap: 5px;
        margin: 0;
    }

    .homeTab_actionBlock {
        gap: 32px;
        width: 100%;
    }
}

@media only screen and (max-width: 550px) {


    .homeTab_actionBlock {
        border-radius: 4px;
        padding: 16px;
    }
}

.file__picker {
    height: 74vh;
    padding-bottom: 8px !important;
}

.file__picker__pdfeditor {
    height: 56vh;
    padding-bottom: 8px !important;
}

.preview__closeBtn {
    margin: 10px;
    margin-left: auto;
}

.custom__btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-background);
    padding: 0 24px;
    font-size: inherit;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 500;
    transition: box-shadow 0.2s;
    border: 1px solid var(--secondary) !important;
    color: var(--secondary) !important;
    min-height: 32px;
    height: auto;
    min-width: 72px;
    border-radius: 16px;

    &:hover {
        border: 1px solid var(--secondary_light) !important;
    }

    &>input {
        position: absolute;
        opacity: 0;
        width: 85px;

        // z-index: -1;
    }

    &>div {
        width: 21px;
        height: 21px;
    }
}



.home__bottom__wrapper {}

@include media-down(tablet) {
    .homeTab_banneer_content {
        padding-left: 20px !important;
        padding-right: 20px !important;

    }

    .toolTab_wrapper {
        padding: 0;
        width: 100vw;
        padding-right: 10px;
    }

    .toolTab_wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }

    .home__bottom__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .homeTab .homeTab_content .homeTab_banneer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .banner__title {
        font-size: $font-size-h3;
        line-height: $line-height-h3;
        font-weight: 700;
    }

    .banner__subtitle {
        font-size: $font-size-normal;
        line-height: $line-height-normal;
    }
}

@include media-down(mobile) {
    .homeTab_banneer_content {
        padding-left: 15px !important;
        padding-right: 15px !important;

    }

    .banner__title {
        font-size: $font-size-h4;
        line-height: $line-height-h4;
        font-weight: 700;
    }

    .banner__subtitle {
        font-size: $font-size-sm;
        line-height: $line-height-sm;
    }
}