.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-background);
  border: solid 1px rgba(22, 33, 57, .2);
  border-radius: 6px;
  height: 44px;
  padding: 0 24px;
  font-size: inherit;
  cursor: pointer;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  transition: box-shadow .2s;
}

.btn--primary {
  background-color: var(--primary) !important;
  color: #fff;
  border: none;
}

.btn--secondary {
  background-color: var(--secondary) !important;
  color: #fff !important;
  border: none;
}

.btn--neutral {
  background-color: var(--body-background);
  color: var(--text) !important;
  border: 1px solid var(--color-gray-light);

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: block;
}