.img {
  display: block;
  overflow: hidden;
  border-radius: $formBorderRadius;
  margin: spacing(4) 0;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
