.table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: $layoutBorder;
  margin-bottom: spacing(2);
  tr {
    &:nth-child(even) {
      background-color: $bg-lighter;
    }
  }

  td {
    padding: spacing(1);
    height: 48px;
  }
  th {
    padding: spacing(1);
    border-bottom: $layoutBorder;
    text-align: left;
  }
  &__scrollWrapper {
    max-width: 100%;
    overflow-x: auto;
  }
  &--hover {
    tr {
      cursor: pointer;
      &:hover {
        background-color: $bg-light;
      }
    }
  }
}
