.grid {
  display: grid;
  grid-gap: spacing(3);
  &--2 {
    @include media-up(tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &--3 {
    @include media-up(tablet) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-up(desktop) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &--4 {
    @include media-up(tablet) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-up(desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &--2_1 {
    @include media-up(tablet) {
      grid-template-columns: 2fr 1fr;
    }
  }
  &--1_2 {
    @include media-up(tablet) {
      grid-template-columns: 1fr 2fr;
    }
  }
  &--4 {
    @include media-up(tablet) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-up(desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &--sm {
    @include media-up(tablet) {
      grid-gap: spacing(1);
    }
    @include media-up(desktop) {
      grid-gap: spacing(1.5);
    }
  }
  &--gapNone {
    grid-gap: 0;
    @include media-up(tablet) {
      grid-gap: 0;
    }
    @include media-up(desktop) {
      grid-gap: 0;
    }
  }
  &--gapSm {
    @include media-up(tablet) {
      grid-gap: spacing(1);
    }
    @include media-up(desktop) {
      grid-gap: spacing(1.5);
    }
  }
  &--gapLg {
    @include media-up(tablet) {
      grid-gap: spacing(6);
    }
    @include media-up(desktop) {
      grid-gap: spacing(6);
    }
  }
  &--alignCenter {
    align-items: center;
  }
  &--alignStart {
    align-items: start;
  }
  &--alignEnd {
    align-items: end;
  }
  .card {
    margin: 0;
  }
}
