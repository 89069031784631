.respImg {
  display: block;
  overflow: hidden;
  border-radius: $formBorderRadius;
  box-shadow: $elevate-2;
  margin: spacing(6) 0;

  // @media (min-width: $contentWidthMax) {
  //   margin: spacing(6) -152px;
  // }

  // @include media-up($contentWidthMax) {
  // }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
