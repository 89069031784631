.fixedImg {
  display: block;
  overflow: hidden;

  img {
    display: block;
    border-radius: $formBorderRadius;
    // box-shadow: $elevate-2;
    margin: spacing(4) 0;
    max-width: 100%;
    height: auto;
  }
}
.image {
  &--center {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &--right {
    img {
      float: right;
    }
  }
  &--shadow {
    img {
      box-shadow: $elevate-2;
    }
  }
}
