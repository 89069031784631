.tool_tab_wrapper{
display: flex;

position: relative;
flex-direction: column;
margin: 3rem;
row-gap: 10px;
}

.tab_header{
    height: 30px;
display: flex;
position: relative;
align-items: center;
justify-items: start;
}
.nav{
    position: relative;
}
.tab-content{
    position: relative;
}