/* font size */
$fontSizeBase: 15px;
$fontSizeLG: 17px;
$fontSizeSM: 14px;
$fontSizeXS: 12px;

$fontSizeH1: 56px;
$fontSizeH1tablet: 40px;
$fontSizeH1mobile: 32px;

$fontSizeH2: 28px;
$fontSizeH2tablet: 26px;
$fontSizeH2mobile: 22px;

$fontSizeH3: 22px;
$fontSizeH3tablet: 20px;
$fontSizeH3mobile: 18px;

$fontSizeH4: 18px;
$fontSizeH4tablet: 16px;
$fontSizeH4mobile: 16px;

$fontSizeH5: $fontSizeBase;

$fontWeightDefault: 400;
$fontWeightSemiBold: 500;
$fontWeightBold: 600;
