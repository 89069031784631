// https://noeldelgado.github.io/shadowlord/#c71217
$primary-50: #f9e7e8;
$primary-100: #f4d0d1;
$primary-200: #e9a0a2;
$primary-300: #dd7174;
$primary-400: #d24145;
$primary-500: #c71217;
$primary-600: #9f0e12;
$primary-700: #770b0e;
$primary-800: #500709;
$primary-900: #280405;

// https://noeldelgado.github.io/shadowlord/#3660b3
$secondary-25: #f5f7fb;
$secondary-50: #ebeff7;
$secondary-100: #ebeff7;
$secondary-150: #c3cfe8;
$secondary-200: #afbfe1;
$secondary-300: #86a0d1;
$secondary-400: #5e80c2;
$secondary-450: #4a70bb;
$secondary-500: #3660b3;
$secondary-600: #2b4d8f;
$secondary-700: #203a6b;
$secondary-800: #162648;
$secondary-900: #0b1324;

$bodyBg: #fff;
$titleTextColor: #0b1324;
$textColor: #333333;
$textColorMuted: rgba(51, 51, 51, 0.6);
$textColorContrast: #fff;
$textColorContrastMuted: rgba(255, 255, 255, 0.6);

$bg-light: #edeeef;
$bg-lighter: #f6f6f7;
$bg-lightest: #fafafa;

$linkColor: $secondary-500;
$linkHoverColor: $secondary-300;

$colorPrimaryLight: $primary-300;
$colorPrimary: $primary-500;
$colorPrimaryDark: $primary-700;
$colorPrimaryContrast: #fff;

$colorSecondaryLight: $secondary-300;
$colorSecondary: $secondary-500;
$colorSecondaryDark: $secondary-700;
$colorSecondaryContrast: #fff;

$colorGreyLighter: #f4f7fa;
$colorGreyLight: #e0e4e8; //#d8dadc;
$colorGrey: #b6b8ba;
$colorGreyDark: #626466;
$colorGreyDarker: #424446;

$colorInfo: #0099cc;
$colorInfoContrast: #fff;
$colorSuccess: #0eb852;
$colorSuccessContrast: #fff;
$colorWarning: #ff8800;
$colorWarningContrast: #fff;
$colorError: #cc0000;
$colorErrorContrast: #fff;
