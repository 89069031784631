.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: $formBorder;
  border-radius: $formBorderRadius;
  height: spacing(5.5);
  padding: 0 spacing(3);
  font-size: inherit;
  cursor: pointer;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  font-weight: $fontWeightSemiBold;
  &:hover,
  &:focus {
    box-shadow: $elevate-2;
    color: inherit;
  }
  &:active {
    box-shadow: $elevate-1;
    color: inherit;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  &__startIcon {
    margin-right: spacing(1);
    margin-left: spacing(-1);
    &.btnLoader {
      margin-right: 11px;
      margin-left: -5px;
    }
  }
  &__endIcon {
    margin-right: spacing(-1);
    margin-left: spacing(1);
  }
  &--file {
    position: relative;
    overflow: hidden;
    input {
      position: absolute;
      left: -10px;
      top: -10px;
      width: 0;
      height: 0;
    }
  }
  &--primary {
    background-color: $colorPrimary;
    color: $colorPrimaryContrast;
    border: none;
    &:hover,
    &:focus,
    &:active {
      color: $colorPrimaryContrast;
    }
  }
  &--secondary {
    background-color: $colorSecondary !important;
    color: $colorSecondaryContrast !important;
    border: none;
    &:hover,
    &:focus,
    &:active {
      color: $colorSecondaryContrast;
    }
  }
  &--small {
    height: spacing(4);
    padding: 0 spacing(2);
  }
  &--large {
    height: spacing(6);
    padding: 0 spacing(5);
  }
  &--round {
    width: spacing(5.5) !important;
    padding: 0;
    justify-content: center;
    border-radius: 50%;
    &.btn {
      &--small {
        width: spacing(4) !important;
      }
      &--large {
        width: spacing(6) !important;
      }
    }
  }
  &--link {
    border: none;
    background-color: transparent;
    font-weight: $fontWeightSemiBold;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
    &.btn {
      &--primary {
        color: $colorPrimary;
      }
      &--secondary {
        color: $colorSecondary;
      }
    }
  }
  &--icon {
    border: none;
    background-color: transparent;
    width: spacing(5.5);
    justify-content: center;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
    &.btn {
      &--small {
        width: spacing(4);
      }
      &--large {
        width: spacing(6);
      }
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
  &:before {
    content: ' ';
    width: spacing(6);
    height: spacing(6);
    border: 5px solid $secondary-200;
    border-top-color: $secondary-500;
    border-radius: 50%;
    animation: rotate 0.9s infinite linear;
  }
}

.btnLoader {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 2px solid $secondary-200;
  border-top-color: $secondary-500;
  animation: rotate 0.9s infinite linear;
  .btn--primary &,
  .btn--secondary & {
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-top-color: rgba(255, 255, 255, 1);
  }
  .link & {
    margin-left: 0;
  }
}

.btnRow {
  display: flex;
  align-items: center;
  margin: spacing(6) 0 spacing(3) 0;
  &--center {
    justify-content: center;
  }

  @include media-up(mobile) {
    > *:not(:first-child) {
      margin-left: spacing(2);
    }
  }
  @include media-down(mobile) {
    flex-direction: column;
    .btn {
      width: 100%;
      &:not(:first-child) {
        margin-top: spacing(1);
      }
    }
  }
}
