@import './variables/functions';
@import './variables/devices';
@import './variables/breakpoints';
@import './variables/colors';
@import './variables/fonts';
@import './variables/elevate';
@import './variables/layout';

// colors
$gray-lighter: #ffffff;
$gray-light: #f4f4f4;
$gray: #b9b9b9;
$gray-dark: #777777;
$gray-darker: #393939;

$blue-light: #666666;
$blue: #555555;
$blue-dark: #444444;

$selection-color: #0099cc;

$icon-color: #222222;
$font-color: #333333;

// common
@mixin font-style {
  font-size: 15px;
  font-family: 'Montserrat';
}

// toolbar
$toolbar-bg-color: $gray-lighter;
$toolbar-height: 50px;
$toolbar-btn-width: 50px;
$toolbar-btn-height: 50px;
$toolbar-icon-size: 18px;
$toolbar-icon-color: $icon-color;

// navigation-panel
$navigation-panel-bg-color: #ffffff;

// document-view
$document-view-bg-color: $gray-light;

// loader
$loader-color: $blue-light;

// Contextbar
$contextbar-bg-color: $gray-lighter;
$contextbar-icon-color: $gray-darker;
$contextbar-font-color: $font-color;
$contextbar-height: 40px;
$contextbar-btn-width: 50px;
$contextbar-padding: 0 10px 0 10px;
$contextbar-shadow: 0 3px 8px rgba(black, 0.4);

// popup
$popup-bg: $gray-lighter;
$popup-color: $font-color;
$popup-shadow: 0 3px 8px rgba(black, 0.4);
$popup-content-bg: #ffffff;
$popup-content-color: $font-color;
$popup-content-border: solid 1px $gray;

$toolbar-seperator-width: 25px;
$toolbar-btn-sm-width: 38px;

@import './reset';
@import './typography';
@import './button';
@import './forms';

@import '@pdf-tools/four-heights-pdf-web-viewer/scss/themes/default/_theme';
@import '@pdf-tools/four-heights-pdf-web-viewer/scss/common/index';
@import '@pdf-tools/four-heights-pdf-web-viewer/scss/pdf-viewer-canvas/index';
@import '@pdf-tools/four-heights-pdf-web-viewer/scss/pdf-web-viewer/index';

.pwv-dropdown-panel {
  box-sizing: content-box;
}
#viewer {
  height: 100vh;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  transition: all 0.25s;
  position: relative;
  &.modal {
    filter: blur(1px);
    transition: all 0.25s;
  }
}
.pwv-contextbar {
  border-radius: 10px;
  .pwv-contextbar-content {
  }
}

.pwv-colorpicker {
  .pwv-dropdown-panel {
    width: 150px;
  }
}

.pwv-colorpicker-btn {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 17px;
  margin: 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  &.pwv-selected {
    border: none;
    box-shadow: 0 0 2px rgba(90, 90, 90, 0.8),
      0 0 9px rgba(0, 0, 0, 0.4);
  }
}

.pwv-canvas-toolbar {
  .pwv-toolbar {
    justify-content: center;
    .pwv-commandbar {
      height: 50px;
    }
  }
}

.pwv-freetexteditor {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

.hide-annotationbar {
  .pwv-annotationbar {
    display: none;
  }
}

/*
 * annotation selection border
 */

.pwv-annotation-selection {
  &:not(.pwv-annotation-moving):not(.pwv-annotation-resizing) {
    .pwv-annotation-border {
      top: -9px;
      left: -9px;
      right: -9px;
      bottom: -9px;
      border-style: dashed;
      transition: all 0.1s;
    }
    .pwv-annotation-border-resizehandle-nw,
    .pwv-annotation-border-resizehandle-ne,
    .pwv-annotation-border-resizehandle-sw,
    .pwv-annotation-border-resizehandle-se {
      border-style: none;
      background-color: $selection-color;
    }
    .pwv-annotation-border-resizehandle-nw {
      top: -16px;
      left: -16px;
    }
    .pwv-annotation-border-resizehandle-ne {
      top: -16px;
      right: -16px;
    }
    .pwv-annotation-border-resizehandle-sw {
      bottom: -16px;
      left: -16px;
    }
    .pwv-annotation-border-resizehandle-se {
      bottom: -16px;
      right: -16px;
    }
  }
}
