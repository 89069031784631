$layoutBorderColor: rgba(22, 33, 57, 0.3);
$layoutBorderColorMuted: rgba(22, 33, 57, 0.15);

$layoutBorder: solid 1px $layoutBorderColor;
$layoutBorderRadius: 10px;

$formBorder: solid 1px rgba(22, 33, 57, 0.2);
$formBorderMuted: solid 1px $layoutBorderColorMuted;
$formBorderRadius: 4px;

$headerHeight: 80px;
$mobileHeaderHeight: 56px;
